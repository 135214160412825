<template>
  <cargill-section-header class="total-plan-dashboard-header">
    <div>
      <span>
        {{ translate('headerTitle') }}
      </span>
    </div>

    <div class="total-plan-dashboard-header__actions">
      <div v-if="period && period.length != 0">
        <span>
          {{
            `${$t(
              'application.pages.monthlyPlanningDashboardRevised.selectedPeriod'
            )}:`
          }}
        </span>
        <span class="font-weight-regular">
          <span class="primary--text">
            {{ period[0] }}
          </span>
          <span>
            {{ $t('application.misc.till') }}
          </span>
          <span class="primary--text">
            {{ period[1] }}
          </span>
        </span>
      </div>
      <bc-divider vertical class="mx-2"></bc-divider>
      <span> {{ translate('cropYear') }} {{ year }}</span>
      <bc-divider vertical class="mx-2"></bc-divider>
      <div class="total-plan-dashboard-header__action">
        <dashboard-filter
          :filters="filterDefinition"
          v-model="$filter"
        ></dashboard-filter>
        <bc-btn
          icon
          x-small
          class="dashboard-chart__action-btn"
          @click="$emit('exportPpt')"
        >
          <bc-icon size="14" color="light-grey">fas fa-file-powerpoint</bc-icon>
        </bc-btn>
      </div>
    </div>
  </cargill-section-header>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { CargillSectionHeader } from '../cargill-section'
import { DashboardFilter } from '../dashboards'
import moment from 'moment'

export default defineComponent({
  name: 'TotalPlanDashboardHeader',
  components: {
    CargillSectionHeader,
    DashboardFilter
  },
  props: {
    filterDefinition: {
      type: Array,
      required: true
    },
    period: {
      type: Array
    },
    year: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      filter: {}
    }
  },
  computed: {
    $filter: {
      get() {
        return this.filter
      },
      set(newValue) {
        if (newValue.period.length == 2) {
          newValue.period[0] = moment(newValue.period[0])
            .startOf('month')
            .format('YYYY-MM-DD')
          newValue.period[1] = moment(newValue.period[1])
            .endOf('month')
            .format('YYYY-MM-DD')
        }
        this.$emit('filter-change', { ...newValue })
      }
    }
  },
  methods: {
    translate(key) {
      return this.$t('controlTower.pages.totalPlanDashboard.' + key)
    },
    onExportData() {},
    exportPpt() {
      console.log('chegou aqui')
      this.$emit('exportPpt')
    }
  }
})
</script>

<style lang="scss" scoped>
.total-plan-dashboard-header {
  display: flex;
  justify-content: space-between;
  font-size: 16px;

  &__actions,
  &__action {
    display: flex;
    align-items: center;
    font-weight: 400;
  }

  &__action {
    font-weight: normal;

    &:last-child {
      margin-right: -8px;
    }
  }

  &__action-btn {
    margin: -8px 0;
  }
}
</style>
